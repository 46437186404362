import "core-js/modules/es.array.every";import "core-js/modules/es.object.keys";import "core-js/modules/es.object.to-string";import "core-js/modules/es.regexp.exec";import "core-js/modules/es.string.iterator";import "core-js/modules/es.string.split";import "core-js/modules/web.dom-collections.iterator";import "core-js/modules/web.url"; //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import { getToken } from 'api/qiniu'

export default {
  name: 'EditorSlideUpload',
  props: {
    color: {
      type: String,
      "default": '' } },


  computed: {
    action: function action() {
      return (
        process.env.VUE_APP_API_PREFIX_URL +
        process.env.VUE_APP_UPLOAD_URL +
        this.url);

    } },

  data: function data() {
    return {
      dialogVisible: false,
      listObj: {},
      fileList: [],
      imgList: '' };

  },
  methods: {
    checkAllSuccess: function checkAllSuccess() {var _this = this;
      return Object.keys(this.listObj).every(
      function (item) {return _this.listObj[item].hasSuccess;});

    },
    handleSubmit: function handleSubmit() {var
      imgList = this.imgList;

      if (imgList) {
        var arr = imgList.split(';');

        this.$emit('successCBK', arr);
      }
      this.imgList = '';
      // const arr = Object.keys(this.listObj).map(v => this.listObj[v]);
      // if (!this.checkAllSuccess()) {
      // 	this.$message("请等待所有图片上传完毕，如果出现网络错误，请重新上传！");
      // 	return;
      // }
      // this.$emit("successCBK", arr);
      // this.listObj = {};
      // this.fileList = [];
      this.dialogVisible = false;
    },
    handleSuccess: function handleSuccess(response, file) {var
      uid = file.uid;
      var objKeyArr = Object.keys(this.listObj);

      for (var i = 0, len = objKeyArr.length; i < len; i++) {
        if (this.listObj[objKeyArr[i]].uid === uid) {
          this.listObj[objKeyArr[i]].url = response.files.file;
          this.listObj[objKeyArr[i]].hasSuccess = true;
          return;
        }
      }
    },
    handleRemove: function handleRemove(file) {var
      uid = file.uid;
      var objKeyArr = Object.keys(this.listObj);

      for (var i = 0, len = objKeyArr.length; i < len; i++) {
        if (this.listObj[objKeyArr[i]].uid === uid) {
          delete this.listObj[objKeyArr[i]];
          return;
        }
      }
    },
    beforeUpload: function beforeUpload(file) {
      // eslint-disable-next-line no-underscore-dangle
      var _self = this;

      // eslint-disable-next-line no-underscore-dangle
      var _URL = window.URL || window.webkitURL;
      var fileName = file.uid;

      this.listObj[fileName] = {};
      return new Promise(function (resolve) {
        var img = new Image();

        img.src = _URL.createObjectURL(file);
        img.onload = function () {
          _self.listObj[fileName] = {
            hasSuccess: false,
            uid: file.uid,
            width: this.width,
            height: this.height };

        };
        resolve(true);
      });
    } } };